
.modal{
    transition: all 2s ease !important;
}

.wetra{
    width: 200px;
  }
  
  .contimg{
    display: flex;
    justify-content: center;
  }

  .email_wrapper, .pswd_wrapper{
    display: flex;
    justify-content: center;
    margin-top: 18px;
  }

.inp_box{
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    outline: 0;
    text-align: center;
}

.inp_box:focus{
    border-color: #0d6efd;
    /* border: 2px solid rgb(93, 93, 245); */
    box-shadow: 0px 0px 5px #0d6efd;
}

.submit__weapper{
    margin-top: 1.4em;
}

.btnnn{
    color: #fff;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    background-color: #409fff;
    width: 100%;
    padding: 12px;
    border-radius: 4px;
    outline: 0;
    border: 1px solid #409fff;
}

.errrr{
    color: rgb(186, 17, 0);
    text-align: center !important;
    display: flex;
    justify-content: center;
    margin-top: 18px;
}