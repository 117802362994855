
.big{
  height: 91vh !important;
  width: 100vw;
  background-image: url(./media/pixx/aaa.png);
  background-repeat: no-repeat !important;
  background-size: cover;
}

.small{
  height: 9.2vh !important;
  background-image: url(./media/pixx/bbb.png);
  background-repeat: no-repeat !important;
  background-size: cover;
}


.modal{
  position: absolute;
  left: 530px;
  top: 50px;
  width: 300px;
  border-radius: 10px;
  padding: 20px;
  z-index: 2;
  background: white;
}

.proxx___y___{
  width: 420px;
  height: 450px;
  background-color: transparent;
  cursor: pointer;
  position: fixed;
  bottom: 0;
}